<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t($route.name) }}</b-card-title>
      <b-button
        class=""
       variant="primary"
        @click="openModal(null, 'Create','Create new banner', filterItems)"
        v-if="resolvePermission('banners.create')"
      >
        <feather-icon icon="PlusIcon" size="15"> </feather-icon>
        {{ $t("add new") }}
      </b-button>
    </b-card-header>
    <b-row class="mx-1">
      <b-col class="col-11">
        <b-form-group :label="$t('Search')" label-for="basicInput">
          <b-form-input id="basicInput" v-model="searchText" />
        </b-form-group>
      </b-col>
      <!-- <b-col class="col-4">
        <b-form-group :label="$t('By Slider')" label-for="basicInput">
          <v-select
          :options="sliders"
          v-model="selectedSlider"
          @input="filterItems"
          :loading="slidersLoader"
          @search="searchSliders"
          label="key"
          clearable
        >
        </v-select>
        </b-form-group>
      </b-col> -->
      <b-col class="col-1 align-self-center">
        <b-button
          variant="primary"
          class="btn my-auto"
          :disabled="getLoading"
          @click="filterItems"
        >
          <feather-icon icon="SearchIcon" size="16"></feather-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-table responsive :items="banners" :fields="fields" class="mb-0">
      <template #cell()="data">
        {{ data.value }}
      </template>
      <template #cell(slider_id)="data">
        {{  sliders&&sliders.find(el => el.id == data.value)?sliders.find(el => el.id == data.value).key:data.value }}
      </template>
      <template #cell(image)="data">
        <img
          :src="data.item.image_url ? data.item.image_url : placeHolder"
          width="40"
          height="38"
          class="rounded"
          style="object-fit: cover;"
        />
      </template>
      <template #cell(status)="data">
        <div>
          <b-avatar
            size="32"
            :variant="`light-${data.value ? 'success' : 'danger'}`"
          >
            <feather-icon :icon="data.value ? 'CheckIcon' : 'XIcon'" />
          </b-avatar>
        </div>
      </template>
      <template #cell(title)="data">
        <p class="text-truncate text-nowrap m-0" style="max-width: 300px">
          {{ data.value }}
        </p>
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="editItem(row)"
          variant="flat-dark"
          class="btn-icon"
          v-if="resolvePermission('banners.edit')"
        >
          <feather-icon icon="Edit2Icon" size="15" />
        </b-button>
        <b-button
          size="sm"
          @click="deleteItem(row)"
          variant="flat-danger"
          class="btn-icon"
          v-if="resolvePermission('banners.delete')"
        >
          <feather-icon icon="Trash2Icon" size="15" />
        </b-button>
      </template>
    </b-table>
    <p class="text-center py-4" v-if="banners.length == 0">
      {{ $t("no") + $t($route.name) + $t("noDataHint") }}
    </p>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @change="filterItems"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { mapActions, mapGetters } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormSelect,
    vSelect,
    BPagination,
    BAvatar,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
        },
        {
          key: "image",
          label: "",
        },
        {
          key: "title",
          label: this.$t("title"),
        },
        {
          key: "slider_id",
          label: this.$t("Slider"),
        },
        {
          key: "created_at",
          label: "Created at",
          formatter: (value, key, item) => this.formateDate(item),
        },
        {
          key: "status",
          label: this.$t("status"),
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      searchText: "",
      options: [],
      selected: "",
      currentPage: 1,
      perPage: 10,
      rows: 0,
      slidersLoader: false,
      selectedSlider: null,
    };
  },
  props: ["openModal", "closeModal", "confirmDelete"],
  created() {
    this.filterItems();
    this.searchSliders();
  },

  methods: {
    ...mapActions([
      "getBanners",
      "deleteBanner",
      "getSliders"
    ]),
    searchSliders(text){
      this.slidersLoader = true;
    this.getSliders({
      keywords: text
    }).then(res=>{
      this.slidersLoader = false;
    }).catch(err=>{
      this.slidersLoader = false;
    })
    },
    filterItems() {
      setTimeout(() => {
        this.getBanners({
          page: this.currentPage,
          keywords: this.searchText,
          slider_id: this.selectedSlider?this.selectedSlider.id:null,
          with:['sliders']
        }).then(res => {
          this.rows = res.data.meta.total
        })
      }, 100);
    },
    editItem(row) {
      this.openModal(row, "Edit","Edit banner", this.filterItems);
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteBanner, row);
    },
  },

  computed: {
    ...mapGetters(["banners", "getLoading", "sliders"]),
  },
};
</script>